.upcoming {
  color: #666666;
}

.link {
  text-decoration: none;
}

@media (prefers-color-scheme: dark) {
  .upcoming {
    color: #888888;
  }
}
