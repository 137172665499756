.heading {
  margin: 0;
  padding: 20px 15px;
  font-family: 'Noto Sans JP', sans-serif;
  text-align: center;
  color: #22405E;
}

@media (prefers-color-scheme: dark) {
  .heading {
    color: #447FBB;
  }
}
