.cvSection {
  padding-bottom: 12px;
}

.cvSection:not(:first-child) {
  padding-top: 15px;
}

.cvSection:nth-child(odd) {
  margin-left: calc((100vw - min(100vw - 40px, 896px)) / -2);
  margin-right: calc((100vw - min(100vw - 40px, 896px)) / -2);
  padding-left: calc((100vw - min(100vw - 40px, 896px)) / 2);
  padding-right: calc((100vw - min(100vw - 40px, 896px)) / 2);
  background-color: #F5F5F5;
}

@media (prefers-color-scheme: dark) {
  .cvSection:nth-child(odd) {
    background-color: #212121;
  }
}
