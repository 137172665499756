.projectsSection {
  margin-left: calc((100vw - min(100vw - 40px, 896px)) / -2);
  margin-right: calc((100vw - min(100vw - 40px, 896px)) / -2);
  padding: 0 calc((100vw - min(100vw - 40px, 896px)) / 2) 12px;
  background-color: #F5F5F5
}

@media (prefers-color-scheme: dark) {
  .projectsSection {
    background-color: #212121;
  }
}

.spmIcon {
  height: 116px;
}

.savingOurPlanetIcon {
  height: 96px;
}

@media screen and (max-width: 512px) {
  .savingOurPlanetIcon {
    height: 48px;
  }

  .spmIcon {
    height: 58px;
  }
}
