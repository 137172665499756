.hero {
  height: 512px;
  margin-left: calc((100vw - min(100vw - 40px, 896px)) / -2);
  margin-right: calc((100vw - min(100vw - 40px, 896px)) / -2);
  padding-left: calc((100vw - min(100vw - 40px, 896px)) / 2);
  padding-right: calc((100vw - min(100vw - 40px, 896px)) / 2);
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #F5F5F5;
}

@media (prefers-color-scheme: dark) {
  .hero {
    background-color: #212121;
  }
}

.leftSide {
  width: calc(100% - 337px);
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.greeting {
  font-family: 'Noto Sans JP', sans-serif;
  font-size: 1.5em;
  font-weight: bold;
  color: #22405E;
}

@media (prefers-color-scheme: dark) {
  .greeting {
    color: #447FBB;
  }
}

.name {
  margin: 0 0 0 -3px;
  font-family: 'Noto Sans JP', sans-serif;
  font-size: 3em;
  font-weight: bolder;
  color: black;
}

@media (prefers-color-scheme: dark) {
  .name {
    color: white;
  }
}

.subtitle {
  margin-top: 15px;
  font-family: 'Roboto', sans-serif;
  font-size: 1.25em;
}

@media (prefers-color-scheme: dark) {
  .subtitle {
    color: white;
  }
}

.aboutMe {
  margin-top: 20px;
  line-height: 1.5;
  color: #777;
}

.heroImage {
  border: 6px solid #22405E;
  border-radius: 134px;
  height: 256px;
  z-index: 1;
}

@media (prefers-color-scheme: dark) {
  .heroImage {
    color: #447FBB;
  }
}

@media screen and (max-width: 767px) {
  .leftSide {
    width: calc(100% - 281px);
  }
}

@media screen and (max-width: 619px) {
  .hero {
    height: inherit;
    flex-direction: column-reverse;
    padding-top: 32px;
    padding-bottom: 32px;
  }

  .leftSide {
    width: 100%;
  }

  .heroImage {
    border-width: 4px;
    border-radius: 68px;
    height: 128px;
  }
}