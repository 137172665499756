body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Roboto, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: black;
  background-color: white;
}

@media (prefers-color-scheme: dark) {
  body {
    color: white;
    background-color: #171717;
  }
}

a {
  text-decoration: none;
  color: #22405E;
}

@media (prefers-color-scheme: dark) {
  a {
    color: #447FBB;
  }
}
