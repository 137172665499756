.metadata {
  margin: 0 0 6px 0;
  font-size: 0.85em;
  font-weight: bold;
  color: #777777;
}

.subsection {
  margin-bottom: 26px;
}

.subheading {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap-reverse;
  margin-bottom: 8px;
}

.ul {
  margin: 0;
  padding-inline-start: 32px;
}

.li {
  margin: 4px 0;
}

@media screen and (max-width: 512px) {
  .ul {
    padding-inline-start: 28px;
  }
}
