.pdfButton {
  float: right;
  color: white;
  font-weight: bold;
  margin-top: 20px;
  padding: 8px 12px;
  border-radius: 8px;
  background-color: #22405E;
}

@media (prefers-color-scheme: dark) {
  .pdfButton {
    background-color: #447FBB;
  }
}
