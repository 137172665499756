.navLink {
  margin: 0 10px;
  padding: 8px;
  font-family: 'Noto Sans JP', sans-serif;
  font-size: 1.25em;
  color: #22405E;
}

@media (prefers-color-scheme: dark) {
  .navLink {
    color: #447FBB;
  }
}

.activeLink {
  font-weight: bold;
  border-top: 0 solid transparent;
  border-left: 0 solid transparent;
  border-right: 0 solid transparent;
  border-bottom: 4px solid #22405E;
  border-radius: 4px;
}

@media (prefers-color-scheme: dark) {
  .activeLink {
    border-bottom: 4px solid #447FBB;
  }
}
