.button {
  display: flex;
  align-items: center;
  height: 40px;
  margin: 8px 12px;
  padding: 0 15px;
  border-radius: 8px;
  background-color: #22405E;
  text-decoration: none;
}

@media (prefers-color-scheme: dark) {
  .button {
    background-color: #447FBB;
  }
}

.buttonText {
  color: white;
}
