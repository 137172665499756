.projectSection {
  display: flex;
  margin: 60px 0;
}

.projectSection:first-child {
  margin-top: 8px;
}

.imageContainer {
  display: flex;
}

.projectIcon {
  min-width: 128px;
  width: 128px;
  height: 128px;
  margin-right: 32px;
  margin-bottom: 20px;
}

.textAndButtons {
  width: 100%;
}

.projectName {
  margin: 0;
  font-size: 150%;
}

.projectNameMobile {
  display: none;
}

.subtitle {
  margin-left: 15px;
  font-size: 0.75em;
  font-weight: normal;
  color: #777777;
}

.projectDescription {
  margin-top: 8px;
  line-height: 1.5;
}

.projectButtons {
  display: flex;
  flex-wrap: wrap;
  margin: -8px -12px;
}

@media screen and (max-width: 768px) {
  .projectIcon {
    min-width: 96px;
    width: 96px;
    height: 96px;
  }
}

@media screen and (max-width: 512px) {
  .projectSection {
    flex-direction: column;
  }
  .projectName {
    display: none;
  }
  .projectNameMobile {
    display: block;
  }
  .projectIcon {
    min-width: 64px;
    width: 64px;
    height: 64px;
    margin-right: 20px;
    margin-bottom: 0;
  }
}
