.socialLink {
  margin: 0 12px;
}

.socialIconRound {
  margin-top: -1px;
  width: 42px;
  height: 42px;
}

.socialIconSquare {
  width: 40px;
  height: 40px;
}
