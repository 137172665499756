.header {
  position: sticky;
  top: 0;
  width: 100%;
  height: 80px;
  display: flex;
  background-color: #FFFFFFF0;
  backdrop-filter: blur(10px);
  z-index: 100;
  border-bottom: 0.5px solid #C8C7CC;
}

@media (prefers-color-scheme: dark) {
  .header {
    background-color: #171717F0;
    border-bottom: 0.5px solid #404042;
  }
}

.logo {
  width: 50px;
  height: 50px;
  margin: 15px;
}

.nav {
  width: 100%;
  display: flex;
  align-items: center;
}

.flagContainer {
  margin-left: auto;
  margin-right: 20px;
}

.languageFlag {
  border: 0.01em solid white;
}
